var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('v-card',{staticClass:"pa-sm-4 wraperx",attrs:{"flat":"","outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1):_c('v-card',{staticClass:"pa-sm-4 wraperx",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Vehicle Running Logs "),_c('v-spacer'),(
          _vm.$_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Running Logs',
            'Create'
          )
        )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Log ")],1):_vm._e()],1),(
        _vm.$_checkPermission(
          this.auth.permissions,
          'Manage Vehicle Running Logs',
          'Read All'
        ) ||
        _vm.$_checkPermission(
          this.auth.permissions,
          'Manage Vehicle Running Logs',
          'Read Sub'
        )
      )?_c('v-card-title',{staticClass:"justify-center justify-sm-start"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-tabs',{attrs:{"background-color":"transparent"},on:{"change":_vm.changeView},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"overflow-x":"hidden !important","word-break":"keep-all","text-overflow":"ellipsis !important","max-width":"calc(50vw - 50px)","min-width":"0 !important"},attrs:{"tab-value":1}},'v-tab',attrs,false),on),[_vm._v("My")])]}}],null,false,195361272)},[_c('span',[_vm._v("View your running logs")])]),(
              _vm.$_checkPermission(
                this.auth.permissions,
                'Manage Vehicle Running Logs',
                'Read Sub'
              )
            )?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(
              _vm.$_checkPermission(
                this.auth.permissions,
                'Manage Vehicle Running Logs',
                'Read Sub'
              )
            )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"overflow-x":"hidden !important","word-break":"keep-all","text-overflow":"ellipsis !important","max-width":"calc(50vw - 50px)","min-width":"0 !important"},attrs:{"tab-value":3}},'v-tab',attrs,false),on),[_vm._v("Sub")])]}}],null,false,3533319786)},[_c('span',[_vm._v("View running logs of your subordinates")])]):_vm._e(),(
              _vm.$_checkPermission(
                this.auth.permissions,
                'Manage Vehicle Running Logs',
                'Read All'
              )
            )?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(
              _vm.$_checkPermission(
                this.auth.permissions,
                'Manage Vehicle Running Logs',
                'Read All'
              )
            )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"overflow-x":"hidden !important","word-break":"keep-all","text-overflow":"ellipsis !important","max-width":"calc(50vw - 50px)","min-width":"0 !important"},attrs:{"tab-value":2}},'v-tab',attrs,false),on),[_vm._v("All")])]}}],null,false,4093620878)},[_c('span',[_vm._v("View all running logs")])]):_vm._e()],1)],1)],1):_vm._e(),_c('v-card-title',{staticClass:"primary--text"},[_c('v-text-field',{attrs:{"filled":"","dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.review_status",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","dark":"","color":item.review_status == 'APPROVED'
              ? 'green'
              : item.review_status == 'REJECTED'
              ? 'error'
              : 'amber darken-2'}},[_vm._v(_vm._s(item.review_status == "APPROVED" ? "Approved" : item.review_status == "REJECTED" ? "Rejected" : "Pending"))])]}},{key:"item.actions",fn:function({ item }){return [(item.edit_status == 'DISABLED')?_c('v-btn',{staticClass:"mx-0 px-0 mt-1",attrs:{"depressed":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" more ")]):_c('v-badge',{attrs:{"bordered":"","color":item.edit_status == 'REQUEST_WAITING'
              ? 'orange'
              : item.edit_status == 'REQUEST_REJECTED'
              ? 'error'
              : 'green',"dot":"","overlap":""}},[_c('v-btn',{staticClass:"mx-0 px-0 mt-1",attrs:{"depressed":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" more ")])],1)]}},{key:"item.description",fn:function({ item }){return [_c('v-sheet',{staticClass:"text-truncate",attrs:{"max-width":"200","color":"transparent"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}}])}),_c('v-card-actions',[_c('v-sheet',{staticClass:"d-flex align-center text-caption px-2",attrs:{"color":"transparent"}},[_c('v-badge',{staticClass:"me-3",attrs:{"dot":"","color":"orange"}}),_vm._v(" Edit Access Requested ")],1),_c('v-sheet',{staticClass:"d-flex align-center text-caption px-2",attrs:{"color":"transparent"}},[_c('v-badge',{staticClass:"me-3",attrs:{"dot":"","color":"red"}}),_vm._v(" Edit Access Rejected ")],1),_c('v-sheet',{staticClass:"d-flex align-center text-caption px-2",attrs:{"color":"transparent"}},[_c('v-badge',{staticClass:"me-3",attrs:{"dot":"","color":"green"}}),_vm._v(" Edit Access Granted ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }